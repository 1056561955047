import * as components from '@element-plus/icons-vue'
import { App } from 'vue'
export default {
  install: (app: App) => {
    for (const key in components) {
      const keyVal = key as keyof typeof components
      const componentConfig = components[keyVal]
      app.component(componentConfig.name, componentConfig)
    }
  }
}
