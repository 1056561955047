<template>
  <div>
    <div class="button-image" @click.stop="preview">
      <el-image :src="url" v-if="type == 1" :style="{ width: `${width}px` }" />
      <div v-else-if="type == 2" class="audio">
        <el-button type="primary" :icon="audioIcon" @click.stop="handlePlayAudio">
          {{ audioIcon === 'VideoPlay' ? '播放' : '暂停' }}
        </el-button>
      </div>
      <slot v-else> <el-button type="primary"> 文件预览 </el-button></slot>
    </div>
    <el-dialog
      v-model="dialogVisible"
      title="预览"
      width="1140px"
      append-to-body
      destroy-on-close
      @closed="closed"
      align-center
      @opened="handleOpened"
    >
      <div class="center-center">
        <img :src="url" fit="contain" v-if="type == 1" />
        <div v-else-if="type == 2" class="audio">
          <el-button type="primary" :icon="audioIcon" @click="handlePlayAudio">
            {{ audioIcon === 'VideoPlay' ? '播放' : '暂停' }}
          </el-button>
        </div>
        <video v-else-if="type == 3" controls class="viedo">
          <source :src="url" type="video/mp4" />
          <source :src="url" type="video/mov" />
        </video>
        <template v-else>
          <div>
            <div class="vue-pdf-view">
              <div class="page-view" :style="scale">
                <vue-pdf-embed
                  :source="pdfState.pdfSource"
                  :page="pdfState.pageNum"
                  id="vue-pdf-embed"
                />
              </div>
            </div>
            <div class="page-tool">
              <div class="page-tool-item" @click="pageUp">上一页</div>
              <div class="page-tool-item">{{ pdfState.pageNum }}/{{ pdfState.numPages }}</div>
              <div class="page-tool-item" @click="pageDown">下一页</div>
              <div class="page-tool-item" @click="pageZoomIn">缩小</div>
              <div class="page-tool-item" @click="pageZoomOut">放大</div>
            </div>
          </div>
        </template>

        <!-- <el-empty v-else description="暂不支持该文件" /> -->
      </div>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import VuePdfEmbed from 'vue-pdf-embed'
import { getFileUrlItem } from '@/api/common'

const props = defineProps({
  width: {
    type: [String, Number],
    default: '180'
  },
  srcUrl: {
    type: String,
    default: '',
    required: true
  },
  type: {
    type: Number,
    default: 1 // 1.图片  2.音频  3.视频  4.pdf
  },
  dialog: {
    type: Boolean,
    default: true
  }
})

const dialogVisible = ref(false)
const url = ref('')

function init() {
  if (!props.dialog && !!props.srcUrl && !props.srcUrl.includes('http')) {
    getFileUrlItem(props.srcUrl).then((res: any) => {
      url.value = res.result
    })
  } else {
    url.value = props.srcUrl
  }
}
if (!props.dialog) {
  // console.log('init', props.srcUrl, props.type, props.dialog)
  init()
}

const pdfState = reactive({
  pdfSource: {
    url: '',
    cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.9.359/cmaps/',
    cMapPacked: true
  },
  // 当前页
  pageNum: 1,
  // 总页数
  numPages: 1,
  scale: 1
})
const scale = computed(() => `transform:scale(${pdfState.scale})`)

function preview() {
  props.dialog &&
    !!props.srcUrl &&
    getFileUrlItem(props.srcUrl).then((res: any) => {
      url.value = res.result
      pdfState.pdfSource.url = res.result
      nextTick(async () => {
        dialogVisible.value = true
        const doc = await VuePdfEmbed.getDocument(pdfState.pdfSource).promise
        // 获取文件总页数
        pdfState.numPages = doc.numPages
      })
    })
}

function closed() {
  document.querySelector('.vue-pdf-view')?.removeEventListener('contextmenu', rightClick)
  if (props.type === 2) {
    audioRef.value.pause()
    audioRef.value = undefined
  }
}

const audioIcon = ref('VideoPlay')
const audioRef = ref<any>({})
function handlePlayAudio() {
  if (!url.value) return

  if (audioIcon.value === 'VideoPlay') {
    audioIcon.value = 'VideoPause'
    audioRef.value = new Audio(url.value)
    audioRef.value.addEventListener('canplaythrough', (event: any) => {
      // console.log(event)
      /* 音频可以播放；如果权限允许则播放 */
      audioRef.value.play()
    })
  } else {
    audioIcon.value = 'VideoPlay'
    audioRef.value.pause()
  }
}

// 上一页
function pageUp() {
  if (pdfState.pageNum >= 1) {
    pdfState.pageNum--
  }
  // 当页面位于首页时，再次点击上一页跳转至尾页
  if (pdfState.pageNum == 0) {
    pdfState.pageNum = pdfState.numPages
  }
}

// 下一页
function pageDown() {
  if (pdfState.pageNum <= pdfState.numPages) {
    pdfState.pageNum++
  }
  // 当页面位于尾页时，再次点击下一页跳转至首页
  if (pdfState.pageNum > pdfState.numPages) {
    pdfState.pageNum = 1
  }
}

function pageZoomOut() {
  if (pdfState.scale < 2) {
    pdfState.scale += 0.1
  }
}
function pageZoomIn() {
  if (pdfState.scale > 1) {
    pdfState.scale -= 0.1
  }
}

const rightClick = (event) => {
  event.preventDefault()
}
const handleOpened = () => {
  document.querySelector('.vue-pdf-view')?.addEventListener('contextmenu', rightClick)
}
</script>

<style lang="scss" scoped>
.button-image {
  cursor: pointer;
}
.viedo {
  max-width: 760px;
}

.vue-pdf-view {
  text-align: center;
  margin-bottom: 20px;

  width: 1100px;
  height: 800px;
  /* 内容溢出显示滚动条 */
  overflow-y: scroll;
  overflow-x: hidden;
}
.page-view {
  transform-origin: 0 0;
}

.page-tool {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  margin: 0 auto;
  background: rgb(66, 66, 66);
  color: white;
  border-radius: 19px;
  z-index: 100;
  cursor: pointer;
  margin-left: 50%;
  transform: translateX(-50%);
}
.page-tool-item {
  padding: 8px 15px;
  padding-left: 10px;
  cursor: pointer;
}
</style>
