<template>
  <div class="upload-file">
    <el-upload
      multiple
      :action="`${env}${uploadFileUrl}`"
      :before-upload="handleBeforeUpload"
      :file-list="fileList"
      :limit="limit"
      :on-error="handleUploadError"
      :on-exceed="handleExceed"
      :on-success="handleUploadSuccess"
      :show-file-list="false"
      :headers="headers"
      class="upload-file-uploader"
      ref="fileUpload"
      :data="{
        path: props.imagePath
      }"
      method="post"
      v-if="fileList.length === 0"
    >
      <!-- 上传按钮 -->
      <slot></slot>
      <!-- <el-button type="primary">选取文件</el-button> -->
    </el-upload>
    <!-- 上传提示 -->
    <div class="el-upload__tip" v-if="showTip || tipText">
      <template v-if="showTip">
        请上传
        <template v-if="fileSize">
          大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b>
        </template>
        <template v-if="fileType">
          格式为 <b style="color: #f56c6c">{{ fileType.join('/') }}</b>
        </template>
        的文件
      </template>
      <template v-if="tipText">{{ tipText }}</template>
    </div>
    <!-- 文件列表 -->
    <transition-group
      class="upload-file-list el-upload-list el-upload-list--text"
      name="el-fade-in-linear"
      tag="ul"
      v-if="isShowFileList"
    >
      <li
        :key="file.uid"
        class="el-upload-list__item ele-upload-list__item-content"
        v-for="(file, index) in fileList"
      >
        <el-link :href="`${file.url}`" :underline="false" target="_blank">
          <span class="el-icon-document"> {{ getFileName(file.name) }} </span>
        </el-link>
        <div class="ele-upload-list__item-content-action">
          <el-link :underline="false" @click="handleDelete(index)" type="danger">删除</el-link>
        </div>
      </li>
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import { getToken } from '@/utils/auth'
import { UploadFile, UploadUserFile } from 'element-plus'
import cloneDeep from 'lodash-es/cloneDeep'
interface IItem extends UploadUserFile {
  value?: string
  url: string
  key: string
}

const props = defineProps({
  modelValue: [String, Object, Array],
  // 上传地址
  uploadFileUrl: {
    type: String,
    default: `/tony-member/members/import`
  },
  // 数量限制
  limit: {
    type: Number,
    default: 5
  },
  // 大小限制(MB)
  fileSize: {
    type: Number,
    default: 50
  },
  // 文件类型, 例如['png', 'jpg', 'jpeg']
  fileType: {
    type: Array,
    default: () => ['mp3']
  },
  // 是否显示提示
  isShowTip: {
    type: Boolean,
    default: true
  },
  tipText: {
    type: String,
    default: ''
  },
  // 文件在服务器中的文件夹
  imagePath: {
    type: String,
    default: ''
  },
  // 是否显示上传的文件列表
  isShowFileList: {
    type: Boolean,
    default: true
  }
})

const { proxy } = getCurrentInstance() as any
const emit = defineEmits(['update:modelValue', 'updateComplete'])
const number = ref(0)
const uploadList = ref<IItem[]>([])
const env = ref(import.meta.env.VITE_APP_BASE_API)
// 上传文件服务器地址
// const uploadFileUrl = ref(
//   `${import.meta.env.VITE_APP_BASE_API || ''}/tony-common/fileUpload/uploadFile`
// )
const headers = ref({ access_token: getToken() })
const fileList = ref<IItem[]>([])
const showTip = computed(() => props.isShowTip && (props.fileType || props.fileSize))

watch(
  () => props.modelValue,
  (val) => {
    console.log('props.modelValue', props.modelValue)
    if (val) {
      let temp = 1
      // 首先将值转为数组
      const list = Array.isArray(val) ? val : val.split(',')
      // 然后将数组转为对象数组
      fileList.value = list.map((item: any) => {
        if (typeof item === 'string') {
          item = { name: item, url: item }
        }
        item.uid = item.uid || new Date().getTime() + temp++
        return item
      })
    } else {
      fileList.value = []
      return []
    }
  },
  { deep: true, immediate: true }
)

// 上传前校检格式和大小
function handleBeforeUpload(file: File) {
  // 校检文件类型
  if (props.fileType.length) {
    const fileName = file.name.split('.')
    const fileExt = fileName[fileName.length - 1]
    const isTypeOk = props.fileType.indexOf(fileExt) >= 0
    if (!isTypeOk) {
      proxy.$modal.msgError(`文件格式不正确, 请上传${props.fileType.join('/')}格式文件!`)
      return false
    }
  }
  // 校检文件大小
  if (props.fileSize) {
    const isLt = file.size / 1024 / 1024 < props.fileSize
    if (!isLt) {
      proxy.$modal.msgError(`上传文件大小不能超过 ${props.fileSize} MB!`)
      return false
    }
  }

  proxy.$modal.loading('正在上传文件，请稍候...')
  number.value++
  return true
}

// 文件个数超出
function handleExceed() {
  proxy.$modal.msgError(`上传文件数量不能超过 ${props.limit} 个!`)
}

// 上传失败
function handleUploadError() {
  proxy.$modal.closeLoading()
  proxy.$modal.msgError('上传文件失败')
}

// 上传成功回调
function handleUploadSuccess(res: any, file: UploadFile) {
  const result = res.result
  if (!!result.fileName) {
    console.log('result', result)
    uploadList.value.push({ name: result.fileName, url: result.tempUrl, key: result.objectKey })
    uploadedSuccessfully(res)
  } else if (res.code == 200) {
    // number.value--
    proxy.$modal.closeLoading()
    proxy.$refs.fileUpload.handleRemove(file)
    uploadedSuccessfully(res)
  } else {
    number.value--
    proxy.$modal.closeLoading()
    proxy.$modal.msgError('上传文件失败')
    proxy.$refs.fileUpload.handleRemove(file)
    uploadedSuccessfully(res)
  }
}

// 删除文件
function handleDelete(index: number) {
  fileList.value.splice(index, 1)
  emit('update:modelValue', cloneDeep(fileList.value))
}

// 上传结束处理
function uploadedSuccessfully(res: any) {
  if (number.value > 0 && uploadList.value.length === number.value) {
    fileList.value = fileList.value.filter((f) => f.url !== undefined).concat(uploadList.value)
    uploadList.value = []
    number.value = 0
    emit('update:modelValue', cloneDeep(fileList.value))
    proxy.$modal.closeLoading()
  }
  emit('updateComplete', res)
}

// 获取文件名称
function getFileName(name: string) {
  if (name.lastIndexOf('/') > -1) {
    return name.slice(name.lastIndexOf('/') + 1)
  } else {
    return name
  }
}
</script>

<style scoped lang="scss">
.upload-file-uploader {
  margin-bottom: 5px;
}
.upload-file-list .el-upload-list__item {
  border: 1px solid #e4e7ed;
  line-height: 2;
  margin-bottom: 10px;
  position: relative;
}
.upload-file-list .ele-upload-list__item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
}
.ele-upload-list__item-content-action .el-link {
  width: 40px;
  margin-right: 10px;
}
</style>
