import useDictStore from '@/store/modules/dict'
import usePermissionStore from '@/store/modules/permission'

export default function getTipContent(value: [string, number]) {
  const menuArray = usePermissionStore().menuArray
  if (value && value instanceof Array && value.length > 0) {
    const menuParent = menuArray.find((item) => item.url && item.component == value[0])
    if (menuParent && menuParent.id) {
      //菜单类型type  0:一级菜单; 1:子菜单; 2:按钮
      const button = menuArray.filter(
        (item) => item.parentId && item.parentId == menuParent.id && item.type == 2
      )
      const permissions = button.find((item) => item.perms == value[1])
      if (permissions) {
        return permissions.name
      }
    }
  }
  const SYS_BUTTON_TYPE = useDictStore().getDict('SYS_BUTTON_TYPE')
  const label = SYS_BUTTON_TYPE?.find((item) => item.value == value[1])?.label
  return String(label)
}
