import {
  ElMessage,
  ElMessageBox,
  ElLoading,
  MessageParamsWithType,
  LoadingParentElement
} from 'element-plus'
import { ComponentPublicInstance, ComponentOptionsBase, Ref } from 'vue'
let loadingInstance: {
  close: any
  setText?: (text: string) => void
  removeElLoadingChild?: () => void
  handleAfterLeave?: () => void
  vm?: ComponentPublicInstance<
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    false,
    ComponentOptionsBase<any, any, any, any, any, any, any, any, any, {}, {}, string>,
    {}
  >
  $el?: HTMLElement
  originalPosition?: Ref<string>
  originalOverflow?: Ref<string>
  visible?: Ref<boolean>
  parent?: Ref<LoadingParentElement>
  background?: Ref<string>
  svg?: Ref<string>
  svgViewBox?: Ref<string>
  spinner?: Ref<string | boolean>
  text?: Ref<string>
  fullscreen?: Ref<boolean>
  lock?: Ref<boolean>
  customClass?: Ref<string>
  target?: Ref<HTMLElement>
  beforeClose?: Ref<(() => boolean) | undefined> | undefined
  closed?: Ref<(() => void) | undefined> | undefined
}

export default {
  // 错误消息
  msg(content: MessageParamsWithType) {
    ElMessage.info(content)
  },
  // 错误消息
  msgError(content: MessageParamsWithType) {
    ElMessage.error(content)
  },
  // 成功消息
  msgSuccess(content: MessageParamsWithType) {
    ElMessage.success(content)
  },
  // 警告
  msgWaring(content: MessageParamsWithType) {
    ElMessage.warning(content)
  },

  // 打开遮罩层
  loading(content: any) {
    loadingInstance = ElLoading.service({
      lock: true,
      text: content,
      background: 'rgba(0, 0, 0, 0.7)'
    })
  },
  // 关闭遮罩层
  closeLoading() {
    loadingInstance.close()
  },
  // 确认窗体
  confirm(content: string) {
    return ElMessageBox.confirm(content, '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
  },
  // 提交内容
  prompt(content: string) {
    return ElMessageBox.prompt(content, '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
  }
}
