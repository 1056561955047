<template>
  <div class="upload">
    <el-upload
      ref="uploadRef"
      class="upload-demo"
      action=""
      :before-upload="beforeUpload"
      multiple
      :limit="limit"
      :on-exceed="handleExceed"
      :file-list="fileList"
      :http-request="handlerUpload"
      :on-success="handleSuccess"
      :show-file-list="false"
    >
      <div v-if="!fileNameSrc"><el-button type="primary"> 点击上传 </el-button></div>
      <div v-else>
        <span class="mr20">{{ fileName }}</span>
        <el-button type="primary"> 重新上传 </el-button>
      </div>
    </el-upload>
  </div>
</template>
<script setup lang="ts">
import OSS from 'ali-oss'
import browserMD5File from 'browser-md5-file'
import {
  getTempUploadCertificate,
  getFileUrlItem,
  uploadIsCheckRepeat,
  uploadIsComplete
} from '@/api/common'
import { FileType, AudioType, VideoType } from '@/utils/find'
const props = defineProps({
  modelValue: String,
  fileType: {
    type: Array,
    default: () => ['mp3']
  },
  fileSize: {
    type: Number,
    default: 500
  },
  limit: {
    type: Number,
    default: 5
  }
})
const emit = defineEmits(['update:modelValue', 'uploadSuccess', 'getVideoDuration'])
const { proxy } = getCurrentInstance() as any

const fileList = ref([])
const aliObj = ref<any>({})
const partSize = ref(1024 * 1024 * 3) // 切片大小
const parallel = ref(5) // 同时上传的切片数
const taskArray = ref<any>([]) // 单线程上传队列
const taskLock = ref(false) // 线程锁
const fileNameSrc = ref('')
const videoDuration = ref(0)
const fileName = ref('')
const md5Flie = ref('')

watch(
  () => props.modelValue,
  (val) => {
    if (val) {
      fileNameSrc.value = val
      const nameArr = val.split('/')
      fileName.value = nameArr[nameArr.length - 1]
    }
  },
  { deep: true, immediate: true }
)

async function beforeUpload(file: any) {
  console.log('beforeUpload', file)
  if (props.fileType.length) {
    const fileName = file.name.split('.')
    const fileExt = fileName[fileName.length - 1]
    const isTypeOk = props.fileType.indexOf(fileExt) >= 0
    if (!isTypeOk) {
      proxy.$modal.msgError(`文件格式不正确, 请上传${props.fileType.join('/')}格式文件!`)
      return false
    }
  }

  if (props.fileSize) {
    const isLt = file.size / 1024 / 1024 < props.fileSize
    if (!isLt) {
      proxy.$modal.msgError(`上传文件大小不能超过 ${props.fileSize} MB!`)
      return false
    }
  }

  // 获取视频时长
  if (JSON.stringify(props.fileType) === JSON.stringify(VideoType)) {
    let url = window.URL.createObjectURL(new Blob([file], { type: 'application/zip' }))
    let audioElement = new Audio(url)
    audioElement.addEventListener('loadedmetadata', function () {
      videoDuration.value = audioElement.duration //playTime就是当前视频长度
    })
  }
  proxy.$modal.loading('正在上传文件，请稍候...')

  // 校验文件是否之前上传过，如果上传过，则直接后端接口返回，如果没有上传过，则上传
  await getMd5(file)
  const isHasFile: any = await uploadIsCheckRepeat({ md5: md5Flie.value }).then((res) => {
    return res
  })
  if (isHasFile.result.canUpload === false) {
    proxy.$modal.closeLoading()
    if (JSON.stringify(props.fileType) === JSON.stringify(VideoType)) {
      emit('getVideoDuration', videoDuration.value)
    }
    fileNameSrc.value = isHasFile.result.objectKey
    emit('update:modelValue', isHasFile.result.objectKey)
    proxy.$modal.msgSuccess('文件上传成功')
  } else {
    console.log('需要上传')
    appendTaskArray(file)
  }
}
function handleExceed() {
  proxy.$modal.msgError(`上传文件数量不能超过 ${props.limit} 个!`)
}
// const uploadRef = ref<HTMLElement>(null)
function handleSuccess(file: any) {
  proxy.$refs.uploadRef.handleRemove(file)
}

function getMd5(rawFile: any) {
  return new Promise((resolve, reject) => {
    const bmf = new browserMD5File()
    bmf.md5(
      rawFile,
      (err: any, md5: any) => {
        console.log(md5) //获取的md5值
        md5Flie.value = md5
        resolve('')
      },
      (progress: any) => {}
    )
  })
}

// 获取OSS临时凭证
function getAliOssKey() {
  getTempUploadCertificate().then((res) => {
    if (res.code === 200) {
      aliObj.value = {
        accessKeyId: res.result.accessKeyId,
        accessKeySecret: res.result.accessKeySecret,
        stsToken: res.result.securityToken,
        bucket: res.result.bucket,
        region: res.result.region
      }
    }
  })
}
getAliOssKey()
function handlerUpload(obj: any) {
  console.log('触发上传')
  if (!taskLock.value) {
    // 锁住 只允许存在上传线程
    taskLock.value = true
    runUpload()
  }
}
function appendTaskArray(file: any) {
  let fileObj = {
    file: file,
    task: function () {
      return new Promise(function () {
        aliOssPost(fileObj.file)
      })
    }
  }
  taskArray.value.push(fileObj)
}
function runUpload() {
  if (taskArray.value.length) {
    let current = taskArray.value.shift()
    current.task()
  } else {
    console.log('上传结束')
    taskLock.value = false
  }
}
// 上传开始
async function aliOssPost(obj: any) {
  console.log('file:', obj)
  fileName.value = obj.name
  let result
  // 大于10M分片上传
  if (obj.size > 1024 * 1024 * 10) {
    result = await multipartUpload(obj.name, obj) // 分片上传
    add_attachment(obj)
  } else {
    result = await put(obj.name, obj) // 普通上传
    add_attachment(obj)
  }
  console.log('result:', result)
}
async function put(name: any, fileUrl: any) {
  let client = new OSS({
    accessKeyId: aliObj.value.accessKeyId,
    accessKeySecret: aliObj.value.accessKeySecret,
    bucket: aliObj.value.bucket,
    // stsToken: aliObj.value.stsToken,
    region: aliObj.value.region,
    secure: true
  })
  try {
    const mood = import.meta.env.VITE_APP_ENV === 'development' ? 'dev' : 'pro'
    let fileName = `${mood}/${aliObj.value.bucket}-video/${name}`
    let result = await client.put(fileName, fileUrl)
    return result
  } catch (e) {
    console.log(e)
  }
}
async function multipartUpload(name: any, fileUrl: any) {
  // 大于3mb 分片上传
  let client = new OSS({
    accessKeyId: aliObj.value.accessKeyId,
    accessKeySecret: aliObj.value.accessKeySecret,
    bucket: aliObj.value.bucket,
    region: aliObj.value.region,
    // stsToken: aliObj.value.stsToken,
    secure: true
  })
  try {
    const mood = import.meta.env.VITE_APP_ENV === 'development' ? 'dev' : 'pro'
    let fileName = `${mood}/${aliObj.value.bucket}-video/${name}`
    console.log(fileName)
    let result = await client.multipartUpload(fileName, fileUrl, {
      parallel: parallel.value,
      partSize: partSize.value,
      headers: {
        'Content-Disposition': 'inline'
      }
    })
    return result
  } catch (e) {
    console.log(e)
  }
}

// 获取上传成功后地址
async function add_attachment(obj: any) {
  const mood = import.meta.env.VITE_APP_ENV === 'development' ? 'dev' : 'pro'
  const fileKey = `${mood}/${aliObj.value.bucket}-video/${obj.name}`
  proxy.$modal.closeLoading()
  if (JSON.stringify(props.fileType) === JSON.stringify(VideoType)) {
    emit('getVideoDuration', videoDuration.value)
  }
  fileNameSrc.value = fileKey
  emit('update:modelValue', fileKey)
  proxy.$modal.msgSuccess('文件上传成功')
  await getMd5(obj)
  const index = obj.name.lastIndexOf('.')
  uploadIsComplete({
    fileName: obj.name.substring(0, index),
    isPublic: false,
    key: fileKey,
    md5: md5Flie.value
  })
  proxy.$refs.uploadRef.handleRemove(obj)
  // getFileUrlItem(fileKey).then((res) => {
  //   if (res.code === 200) {
  //     fileNameSrc.value = res.result
  //     emit('uploadSuccess', fileNameSrc.value)
  //     proxy.$modal.msgSuccess('文件上传成功')
  //   } else {
  //     proxy.$modal.msgError('文件上传失败')
  //     runUpload()
  //   }
  // })
}
</script>
