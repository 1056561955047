import { IDictData } from '@/types/dict'
import request from '@/utils/request'

// 查询字典数据列表
export function listData(query: any) {
  return request({
    url: '/tony-admin/dictData/list',
    method: 'get',
    params: query
  })
}

// 查询字典数据详细
export function getData(dictCode: string | string[]) {
  return request({
    url: '/tony-admin/dictData/' + dictCode,
    method: 'get'
  })
}

// 根据字典类型查询字典数据信息
export function getDicts(dictType: string) {
  return request({
    url: '/tony-admin/dictData/type/' + dictType,
    method: 'get'
  })
}

// 新增字典数据
export function addData(data: IDictData) {
  return request({
    url: '/tony-admin/dictData',
    method: 'post',
    data: data
  })
}

// 修改字典数据
export function updateData(data: IDictData) {
  return request({
    url: '/tony-admin/dictData',
    method: 'put',
    data: data
  })
}

// 删除字典数据
export function delData(dictCode: string | string[]) {
  return request({
    url: '/tony-admin/dictData/' + dictCode,
    method: 'delete'
  })
}

// 查询系统字典
export function getSystemDict(dict: string) {
  return request({
    url: '/tony-common/dictType/selectDictTypeByDictType',
    method: 'get',
    params: { dictTypes: dict }
  })
}
