<template>
  <div class="upload-file">
    <el-upload
      :disabled="disabled"
      :action="uploadUrl"
      :before-upload="handleBeforeUpload"
      :file-list="fileList"
      :limit="limit"
      :on-remove="handleDelete"
      :on-error="handleUploadError"
      :on-exceed="handleExceed"
      :on-success="handleUploadSuccess"
      :headers="headers"
      class="upload-file-uploader"
      ref="fileUpload"
      :data="{
        isPublic: true,
        path: props.filePath
      }"
      method="post"
    >
      <el-button type="primary" v-if="!disabled">选取文件</el-button>
    </el-upload>
    <div class="el-upload__tip" v-if="showTip || tipText">
      <template v-if="showTip">
        请上传
        <template v-if="fileSize">
          大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b>
        </template>
        <template v-if="fileType">
          格式为 <b style="color: #f56c6c">{{ fileType.join('/') }}</b>
        </template>
        的文件
      </template>
      <template v-if="tipText">{{ tipText }}</template>
    </div>
    <!-- 文件列表 -->
    <transition-group
      class="upload-file-list el-upload-list el-upload-list--text"
      name="el-fade-in-linear"
      tag="ul"
    />
  </div>
</template>

<script setup lang="ts">
import { getToken } from '@/utils/auth'
import { UploadFile, UploadUserFile } from 'element-plus'
import cloneDeep from 'lodash-es/cloneDeep'
interface IItem extends UploadUserFile {
  value?: string
  url: string
  key: string
}

const props = defineProps({
  modelValue: [String, Object, Array],
  uploadFileUrl: {
    // 上传地址
    type: String,
    default: `/tony-common/fileUpload/uploadFile`
  },
  disabled: {
    type: Boolean,
    default: false
  },
  // 数量限制
  limit: {
    type: Number,
    default: 1
  },
  fileSize: {
    type: Number,
    default: 1000
  },
  fileType: {
    type: Array,
    default: () => ['json', 'zip']
  },
  isShowTip: {
    type: Boolean,
    default: true
  },
  tipText: {
    type: String,
    default: ''
  },
  filePath: {
    type: String,
    default: ''
  }
})

const { proxy } = getCurrentInstance() as any
const emit = defineEmits(['update:modelValue', 'updateComplete'])
const number = ref(0)
const uploadList = ref<IItem[]>([])
const env = ref(import.meta.env.VITE_APP_BASE_API)
const headers = ref({ access_token: getToken() })
const fileList = ref<IItem[]>(JSON.parse(JSON.stringify(props.modelValue)) || [])
const uploadUrl = ref(
  `${import.meta.env.VITE_APP_BASE_API || ''}/tony-common/fileUpload/uploadFile`
)
const showTip = computed(() => props.isShowTip && (props.fileType || props.fileSize)) // 上传前校检格式和大小
function handleBeforeUpload(file: File) {
  // 校检文件类型
  if (props.fileType.length) {
    const fileName = file.name.split('.')
    const fileExt = fileName[fileName.length - 1]

    const isTypeOk = props.fileType.indexOf(fileExt) >= 0

    if (!isTypeOk) {
      proxy.$modal.msgError(`文件格式不正确, 请上传${props.fileType.join('/')}格式文件!`)
      return false
    }
  }
  // 校检文件大小
  if (props.fileSize) {
    const isLt = file.size / 1024 / 1024 < props.fileSize
    if (!isLt) {
      proxy.$modal.msgError(`上传文件大小不能超过 ${props.fileSize} MB!`)
      return false
    }
  }
  proxy.$modal.loading('正在上传文件，请稍候...')
  number.value++
  return true
}

// 文件个数超出
function handleExceed() {
  proxy.$modal.msgError(`上传文件数量不能超过 ${props.limit} 个!`)
}

// 上传失败
function handleUploadError() {
  proxy.$modal.closeLoading()
  proxy.$modal.msgError('上传文件失败')
}

// 上传成功回调
function handleUploadSuccess(res: any, file: UploadFile) {
  const result = res.result
  if (!!result.fileName) {
    uploadList.value.push({
      name: result.fileName + '.json',
      url: result.tempUrl,
      key: result.objectKey
    })
    uploadedSuccessfully(res)
  } else if (res.code == 200) {
    proxy.$modal.closeLoading()
    proxy.$refs.fileUpload.handleRemove(file)
    uploadedSuccessfully(res)
  } else {
    number.value--
    proxy.$modal.closeLoading()
    proxy.$modal.msgError('上传文件失败')
    proxy.$refs.fileUpload.handleRemove(file)
    uploadedSuccessfully(res)
  }
}

// 删除文件
function handleDelete() {
  emit('update:modelValue', cloneDeep(fileList.value))
}

// 上传结束处理
function uploadedSuccessfully(res: any) {
  if (number.value > 0 && uploadList.value.length === number.value) {
    fileList.value = fileList.value.filter((f) => f.url !== undefined).concat(uploadList.value)
    uploadList.value = []
    number.value = 0
    emit('update:modelValue', cloneDeep(fileList.value))
    proxy.$modal.closeLoading()
  }
  emit('updateComplete', res)
}

// 获取文件名称
function getFileName(name: string) {
  if (name.lastIndexOf('/') > -1) {
    return name.slice(name.lastIndexOf('/') + 1)
  } else {
    return name
  }
}
</script>

<style scoped lang="scss">
.upload-file-uploader {
  margin-bottom: 5px;
}
.upload-file-list .el-upload-list__item {
  border: 1px solid #e4e7ed;
  line-height: 2;
  margin-bottom: 10px;
  position: relative;
}
.upload-file-list .ele-upload-list__item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
}
.ele-upload-list__item-content-action .el-link {
  width: 40px;
  margin-right: 10px;
}
</style>
