<template>
  <div class="copy">
    {{ text }}
    <el-tooltip content="点击复制" placement="top">
      <el-icon color="#409EFF" @click="copy()"><DocumentCopy /></el-icon>
    </el-tooltip>
  </div>
</template>
<script setup lang="ts">
import { copyText } from '@/utils/find'
interface IProps {
  text: string
}
const props = defineProps<IProps>()
const { proxy } = getCurrentInstance() as any
async function copy() {
  await copyText(props.text)
  proxy.$modal.msgSuccess('复制成功')
}
</script>
<style scoped>
.copy {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}
</style>
