// https://test-api.abiefamily.com/swagger-ui.html
import request from '@/utils/request'

// 根据文件key生成临时访问地址   列表
export function getFileUrl(objectKeys: string[]) {
  return request({
    url: '/tony-common/fileDownload/getFileTempUrlByKeys',
    method: 'get',
    params: {
      objectKeys: objectKeys.join(',')
    }
  })
}

export function getFileUrlItem(objectKey: string) {
  return request({
    url: '/tony-common/fileDownload/getFileTempUrlByKey',
    method: 'get',
    params: {
      objectKey
    }
  })
}

// 查询省市区
export function getProvinceCityList(params: any) {
  return request({
    url: '/tony-common/sys-prov-city-dist/listViewable',
    method: 'get',
    params
  })
}

// 获取短信验证码
export function getPhoneCodeApi(data: any) {
  return request({
    url: '/tony-common/sms/send',
    method: 'post',
    data
  })
}
export function checkPhoneCodeApi(data: any) {
  return request({
    url: '/tony-common/sms/check',
    method: 'post',
    data
  })
}

// 获取分片上传凭证
export function getTempUploadCertificate() {
  return request({
    url: '/tony-common/fileUpload/getSignature',
    method: 'post',
    data: "{path:'video/',isPublic:false}"
  })
}
// 校验文件是否需要上传
export function uploadIsCheckRepeat(data: any) {
  return request({
    url: `/tony-common/fileUpload/upload/check-repeat?md5=${data.md5}`,
    method: 'post'
    // data
  })
}

// 文件上传完成，后端用来保存文件信息
export function uploadIsComplete(data: any) {
  return request({
    url: `/tony-common/fileUpload/upload-part/complete`,
    method: 'post',
    data
  })
}
