import { defineStore } from 'pinia'
import type { ILoginFrom } from '@/types/user'
import { login, getInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import cache from '@/plugins/cache'
import logo from '@/assets/images/user.png'

const useUserStore = defineStore('user', {
  state: () => ({
    token: getToken(),
    userInfo: cache.local.getJSON('userInfo') || {},
    name: '',
    avatar: logo,
    userStoreId: '',
    userStoreName: ''
  }),
  actions: {
    // 登录
    login(userInfo: ILoginFrom) {
      const params = {
        ...userInfo,
        userName: userInfo.userName.trim()
      }
      return new Promise((resolve, reject) => {
        login(params)
          .then((res) => {
            const { access_token, user_id, store_id, store_name } = res.result
            console.log(res.result)
            setToken(access_token)
            this.token = access_token
            this.userStoreId = store_id
            this.userStoreName = store_name
            this.getInfo(user_id)
            resolve(user_id)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取用户信息
    getInfo(user_id: string | number) {
      return new Promise((resolve, reject) => {
        getInfo(Number(user_id))
          .then((res) => {
            const user = res.result
            const avatar = user.avatar
              ? user.avatar
              : import.meta.env.VITE_APP_BASE_API + user.avatar

            this.userInfo = res.result
            this.name = user.userName
            // this.avatar = avatar
            // 暂时写死个人中心头像
            this.avatar = logo
            console.log(avatar)
            cache.local.setJSON('userInfo', user)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 退出系统
    logOut() {
      return new Promise((resolve) => {
        this.token = ''
        removeToken()
        cache.local.remove('userInfo')
        resolve(true)
      })
    }
  }
})

export default useUserStore
