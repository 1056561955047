/**
 * v-hasPermi 操作权限处理
 * find
 */

import usePermissionStore from '@/store/modules/permission'
import type { RendererNode, DirectiveBinding } from 'vue'

export default {
  mounted(el: RendererNode, binding: DirectiveBinding) {
    const { value } = binding
    const menuArray = usePermissionStore().menuArray
    if (value && value instanceof Array && value.length > 0) {
      const menuParent = menuArray.find((item) => item.url && item.component == value[0])
      if (menuParent && menuParent.id) {
        //菜单类型  0:一级菜单; 1:子菜单; 2:按钮
        const button = menuArray.filter(
          (item) => item.parentId && item.parentId == menuParent.id && item.type == 2
        )
        const hasPermissions = button.some((item) => item.perms == value[1])
        if (!hasPermissions) {
          el.parentNode && el.parentNode.removeChild(el)
        } //  else {
        //   console.log(el.lastChild)
        //   el.lastChild.innerHTML = hasPermissions.name
        // }
      } else {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error(`请设置操作权限标签值`)
    }
  }
}
