import { defineStore } from 'pinia'
import { constantRoutes } from '@/router'
import Layout from '@/layout/index.vue'
import type { RouteRecordRaw } from 'vue-router'
import { listRouter } from '@/api/menu'
import { handleTree } from '@/utils/find'
import { IMenuItem } from '@/types/menu'
import cloneDeep from 'lodash-es/cloneDeep'

// 匹配views里面所有的.vue文件
const modules = import.meta.glob('./../../views/**/*.vue')

const usePermissionStore = defineStore('permission', {
  state: () => ({
    menuArray: [] as IMenuItem[],
    routes: [] as RouteRecordRaw[],
    addRoutes: [] as RouteRecordRaw[],
    defaultRoutes: [] as RouteRecordRaw[],
    topbarRouters: [] as RouteRecordRaw[],
    sidebarRouters: [] as RouteRecordRaw[]
  }),
  actions: {
    setRoutes(routes: RouteRecordRaw[]) {
      this.addRoutes = routes
      this.routes = constantRoutes.concat(routes)
    },
    setDefaultRoutes(routes: RouteRecordRaw[]) {
      this.defaultRoutes = constantRoutes.concat(routes)
    },
    setTopbarRoutes(routes: RouteRecordRaw[]) {
      this.topbarRouters = routes
    },
    setSidebarRouters(routes: RouteRecordRaw[]) {
      this.sidebarRouters = routes
    },
    generateRoutes() {
      return new Promise<RouteRecordRaw[]>((resolve, reject) => {
        // 向后端请求路由数据
        listRouter()
          .then((res) => {
            let menuArray: IMenuItem[] = res.result ? res.result : []
            this.menuArray = cloneDeep(menuArray)
            //过滤掉类型为按钮的
            menuArray = menuArray.filter((v) => {
              if (v.type == 2) return false //过滤掉类型为按钮的
              if (v.status == 1) return false //过滤掉状态为禁用的
              return true
            })
            const menuTree = handleTree(nestingOpen(cloneDeep(menuArray)), 'id')
            const data = menuTree.map((tree) => parseToRouter(tree))

            const sdata = JSON.parse(JSON.stringify(data))
            const rdata = JSON.parse(JSON.stringify(data))
            const defaultData = JSON.parse(JSON.stringify(data))

            const sidebarRoutes = filterAsyncRouter(sdata)
            const rewriteRoutes = filterAsyncRouter(rdata, true)
            const defaultRoutes = filterAsyncRouter(defaultData)

            this.setRoutes(rewriteRoutes)
            const setSidebarRouters = constantRoutes.concat(sidebarRoutes)
            this.setSidebarRouters(setSidebarRouters)
            this.setDefaultRoutes(sidebarRoutes)
            this.setTopbarRoutes(defaultRoutes)
            resolve(rewriteRoutes as RouteRecordRaw[])
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
})

// 处理菜单套菜单
function nestingOpen(list: IMenuItem[]) {
  list.forEach((item: IMenuItem) => {
    let parent = list.find((v) => v.id == item.parentId)
    while (parent != undefined && parent.type == 1) {
      parent = list.find((v) => v.id == parent?.parentId)
    }
    item.parentId = parent != undefined ? parent.id : 0
  })
  return list
}

// 拆解路由数据
function parseToRouter(tree: any, isFirst = true) {
  const result: any = {}

  if (isFirst && !tree.component) {
    result.component = 'Layout'
  } else if (tree.type == 1) {
    //菜单
    result.component = tree.component
  }
  if (isFirst && tree?.url?.indexOf('/') !== 0) {
    result.path = '/' + tree?.url
  } else {
    result.path = tree?.url
  }
  result.name = result.path.replace(/\/|\:|\-/g, '')
  result.redirect = 'noRedirect'
  result.hidden = !!tree.visible
  result.meta = {
    noCache: false,
    title: tree.name,
    icon: tree.iconName
  }
  if (tree.children && tree.children.length > 0) {
    result.children = tree.children.map((v: any) => {
      return parseToRouter(v, false)
    })
  }
  return result
}
// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap: any[], type = false) {
  return asyncRouterMap.filter((route) => {
    if (type && route.children) {
      route.children = filterChildren(route.children)
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === 'Layout') {
        route.component = Layout
      } else {
        route.component = loadView(route.component)
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, type)
    } else {
      delete route['children']
      delete route['redirect']
    }
    return true
  })
}

function filterChildren(childrenMap: any[]) {
  let children = [] as any[]
  childrenMap.forEach((el) => {
    children = children.concat(el)
  })
  return children
}

export const loadView = (view: string) => {
  let res
  for (const path in modules) {
    const dir = path.split('views/')[1].split('.vue')[0]
    if (dir === view) {
      res = () => modules[path]()
    }
  }
  return res
}

export default usePermissionStore
