import { createApp } from 'vue'
import 'default-passive-events'

import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/zh-cn' // 中文语言
import 'element-plus/theme-chalk/index.css'

import '@/assets/styles/index.scss' // global css

import App from './App.vue'
import store from './store'
import router from './router'
import directive from './directive' // directive

// 注册指令
import plugins from './plugins' // plugins

// svg图标
import 'virtual:svg-icons-register'
import SvgIcon from '@/components/svgIcon/index.vue'
import elementIcons from '@/components/svgIcon/svgicon'

import './router/permission' // permission control

import { useDict } from '@/utils/dict'
import getTipContent from '@/utils/getTipContent'
import { resetForm, addDateRange } from '@/utils/find'

// 分页组件
import Pagination from '@/components/pagination/index.vue'
// 自定义表格工具组件
import RightToolbar from '@/components/rightToolbar/index.vue'
// 图片上传组件
import ImageUpload from '@/components/imageUpload/index.vue'
import OneImgUpload from '@/components/oneImgUpload/index.vue'
import ImgNameUpload from '@/components/imgNameUpload/index.vue'
import FileUpload from '@/components/fileUpload/index.vue'
import OneFileUpload from '@/components/oneFileUpload/index.vue'
import JsonFileUpload from '@/components/jsonFileUpload/index.vue'
import FragmentUpload from '@/components/fragmentUpload/index.vue'
// 文件预览组件
import FilePreview from '@/components/filePreview/index.vue'
// 自定义树选择组件
import TreeSelect from '@/components/treeSelect/index.vue'
// 字典标签组件
import DictTag from '@/components/dictTag/index.vue'

import SearchBtn from '@/components/search/btn.vue'
import TableDate from '@/components/table/date.vue'
import Copy from '@/components/table/copy.vue'
import Total from '@/components/total/index.vue'

const app = createApp(App)

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    useDict: any
    resetForm: any
    addDateRange: any
    getTipContent: any
  }
}

// 全局方法挂载
app.config.globalProperties.useDict = useDict
app.config.globalProperties.resetForm = resetForm
app.config.globalProperties.addDateRange = addDateRange
app.config.globalProperties.getTipContent = getTipContent

// 全局组件挂载
app.component('DictTag', DictTag)
app.component('Pagination', Pagination)
app.component('TreeSelect', TreeSelect)
app.component('ImageUpload', ImageUpload)
app.component('OneImgUpload', OneImgUpload)
app.component('ImgNameUpload', ImgNameUpload)
app.component('FileUpload', FileUpload)
app.component('OneFileUpload', OneFileUpload)
app.component('JsonFileUpload', JsonFileUpload)
app.component('FragmentUpload', FragmentUpload)
app.component('FilePreview', FilePreview)
app.component('RightToolbar', RightToolbar)
app.component('SvgIcon', SvgIcon)
app.component('SearchBtn', SearchBtn)
app.component('TableDate', TableDate)
app.component('Copy', Copy)
app.component('Total', Total)

app.use(router)
app.use(store)
app.use(plugins)
app.use(elementIcons)

directive(app)

// 使用element-plus 并且设置全局的大小
app.use(ElementPlus, {
  locale: locale,
  // 支持 large、default、small
  size: 'default'
})

app.mount('#app')
