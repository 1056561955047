import cloneDeep from 'lodash-es/cloneDeep'
import { getSystemDict } from '@/api/system/dict/data'
import { defineStore } from 'pinia'
export interface IDict {
  label: string | number
  value: string | number | boolean
  listClass?: '' | 'success' | 'warning' | 'info' | 'danger' | 'primary' | undefined
}
export interface IDictList {
  key: string
  value: IDict[]
}

// 初始化字典
const initDictTypeList = ['SYS_OPER_TYPE', 'SYS_BUTTON_TYPE', 'SYS_MENU_TYPE']
const useDictStore = defineStore('dict', {
  state: () => ({
    dict: [] as IDictList[],
    initDictList: [
      {
        key: 'SYS_COMMON_STATUS',
        value: [
          { value: 0, label: '成功' },
          { value: 1, label: '失败' }
        ]
      },
      {
        key: 'SYS_USER_SEX',
        value: [
          { value: 0, label: '女' },
          { value: 1, label: '男' },
          { value: 2, label: '不设置性别' }
        ]
      },
      {
        key: 'SYS_NORMAL_DISABLE',
        value: [
          { value: '0', label: '正常' },
          { value: '1', label: '停用' }
        ]
      },

      {
        key: 'SYS_SHOW_HIDE',
        value: [
          { value: 0, label: '显示' },
          { value: 1, label: '隐藏' }
        ]
      },
      {
        key: 'SYS_OPER_TYPE',
        value: [
          { value: 1, label: '新增' },
          { value: 2, label: '修改' }
        ]
      },
      {
        key: 'SYS_BUTTON_TYPE',
        value: [
          { value: 1, label: '新增' },
          { value: 2, label: '删除' },
          { value: 3, label: '修改' },
          { value: 4, label: '查询' },
          { value: 5, label: '导入' },
          { value: 6, label: '导出' },
          { value: 7, label: '清空' },
          { value: 8, label: '上下架' },
          { value: 9, label: '退款' },
          { value: 10, label: '取消退款' },
          { value: 11, label: '取消订单' },
          { value: 12, label: '认证' },
          { value: 13, label: '入驻' }
        ]
      },
      {
        key: 'SYS_SERVICE_GROUP',
        value: [
          { value: 0, label: '后台', member: false },
          { value: 1, label: '老师', member: false },
          { value: 2, label: '家长', member: false },
          { value: 3, label: '学生', member: true }
        ]
      },
      {
        key: 'SYS_MENU_TYPE',
        value: [
          { value: 0, label: '目录' },
          { value: 1, label: '菜单' },
          { value: 2, label: '按钮' }
        ]
      },
      {
        key: 'SYS_EVENT_STATUS',
        value: [
          { value: 1, label: '未开始' },
          { value: 2, label: '进行中' },
          { value: 3, label: '成功' },
          { value: 4, label: '失败' }
        ]
      },
      {
        key: 'SYS_EVENT_USER_TYPE',
        value: [
          { value: 0, label: '待审批', listClass: 'success' },
          { value: 1, label: '审批通过', listClass: 'primary' },
          { value: 2, label: '已拒绝', listClass: 'danger' }
        ]
      },
      {
        key: 'COLLEGE_LEVEL',
        value: [
          { value: 1, label: '一类院校' },
          { value: 2, label: '二类院校' },
          { value: 3, label: '三类院校' },
          { value: 4, label: '四类院校' },
          { value: 5, label: '国外院校' }
        ]
      },

      {
        key: 'YES_OR_NO',
        value: [
          { value: 0, label: '否' },
          { value: 1, label: '是' }
        ]
      },

      {
        key: 'YES_OR_NO_BOOLEAN',
        value: [
          { value: false, label: '否' },
          { value: true, label: '是' }
        ]
      },
      {
        key: 'STATUS_DISABLE',
        value: [
          { value: 0, label: '停用' },
          { value: 1, label: '启用' }
        ]
      },
      {
        key: 'STATUS_DISABLE_BOOLEAN',
        value: [
          { value: false, label: '停用' },
          { value: true, label: '启用' }
        ]
      },

      // 图书管理模块
      {
        key: 'TEACH_BOOKS_TYPE',
        value: [
          { value: 1, label: '精读' },
          { value: 2, label: '泛读' }
        ]
      },
      {
        key: 'TEACH_STAGE_LIST',
        value: [
          { value: 1, label: '阶段一' },
          { value: 2, label: '阶段二' },
          { value: 3, label: '阶段三' },
          { value: 4, label: '阶段四' }
        ]
      },
      {
        key: 'TEACH_LEVEL_LIST',
        value: [
          { value: 1, label: 'L1' },
          { value: 2, label: 'L2' },
          { value: 3, label: 'L3' },
          { value: 4, label: 'L4' },
          { value: 5, label: 'L5' },
          { value: 6, label: 'L6' }
        ]
      },
      {
        key: 'TEACH_LIBRARY_TYPE',
        value: [
          { value: 1, label: '非虚构' },
          { value: 2, label: '虚构' }
        ]
      },
      {
        key: 'TEACH_QUESTION_TYPE',
        value: [
          { value: 1, label: '听' },
          { value: 2, label: '说' },
          { value: 3, label: '读' },
          { value: 4, label: '写' }
        ]
      },
      {
        key: 'TEACH_QUESTION_ANSWER',
        value: [
          { value: 1, label: '选择' },
          { value: 2, label: '填空' },
          { value: 3, label: '判断' }
        ]
      },
      {
        key: 'TEACH_QUESTION_OPTION',
        value: [
          { value: false, label: '文字' },
          { value: true, label: '图片' }
        ]
      },
      {
        key: 'TEACH_READING_RESOURCE_TYPE',
        value: [
          { value: 0, label: '阅读目标' },
          { value: 1, label: '阅读指导' },
          { value: 2, label: '阅读练习' }
        ]
      },
      // 商品模块
      {
        key: 'GOODS_STATUS_TYPE',
        value: [
          { value: 0, label: '待上架', listClass: 'success' },
          { value: 1, label: '上架', listClass: 'primary' },
          { value: 2, label: '下架', listClass: 'danger' }
        ]
      },
      {
        key: 'GOODS_ITEM_TYPE',
        value: [
          { value: 1, label: '书籍' },
          { value: 2, label: '录播课' },
          { value: 3, label: '直播' },
          { value: 4, label: '其他' }
        ]
      },
      // 订单模块
      {
        key: 'ORDER_ITEM_STATUS',
        value: [
          { value: 0, label: '待支付' },
          { value: 1, label: '已支付' },
          { value: 2, label: '已取消' }
        ]
      },
      {
        key: 'MEMBER_LEVEL',
        value: [
          { value: 0, label: '普通会员' },
          { value: 1, label: '黄金屋会员' }
        ]
      },
      {
        key: 'MEMBER_USER_STATUS',
        value: [
          { value: 1, label: '正常' },
          { value: 2, label: '冻结' },
          { value: 3, label: '黑名单' }
        ]
      },
      {
        key: 'CHANNEL',
        value: [
          { value: 1, label: '门店' },
          { value: 2, label: '小程序（平台）' },
          { value: 3, label: '总部' }
        ]
      },
      // 业务管理模块
      {
        key: 'BUSINESS_MEMBER_STATUS',
        value: [
          { value: 0, label: '已成交' },
          { value: 1, label: '冻结' },
          { value: 2, label: '黑名单' }
        ]
      },
      {
        key: 'BUSINESS_TRACK_STATUS',
        value: [
          { value: 0, label: '正常' },
          { value: 1, label: '冻结' },
          { value: 2, label: '黑名单' }
        ]
      },
      {
        key: 'FOLLOW_UP_STATUS',
        value: [
          { value: 1, label: '预约' },
          { value: 2, label: '到访' },
          { value: 3, label: '签约' },
          { value: 4, label: '无效' },
          { value: 5, label: '有效' }
        ]
      },
      {
        key: 'FOLLOW_UP_RESULT',
        value: [
          { value: 1, label: '愿意到访' },
          { value: 2, label: '预约试听' },
          { value: 3, label: '有兴趣' },
          { value: 4, label: '再联系' },
          { value: 5, label: '号码错误' },
          { value: 6, label: '无人接听' },
          { value: 7, label: '不感兴趣' },
          { value: 8, label: '其他' }

        ]
      },
      {
        key: 'FOLLOW_UP_INTENT',
        value: [
          { value: 0, label: '低' },
          { value: 1, label: '中' },
          { value: 2, label: '高' }
        ]
      },
      {
        key: 'PAY_TYPE',
        value: [
          { value: 1, label: '微信' },
          { value: 2, label: '支付宝' }
        ]
      },
      {
        key: 'STAGE_LIST',
        value: [
          { value: 1, label: '阶段1' },
          { value: 2, label: '阶段2' },
          { value: 3, label: '阶段3' },
          { value: 4, label: '阶段4' },
          { value: 5, label: '阶段5' },
          { value: 6, label: '阶段6' }
        ]
      },
      {
        key: 'CLASS_LEAVE_STATUS',
        value: [
          { value: 0, label: '待审核' },
          { value: 1, label: '已同意' },
          { value: 2, label: '已拒绝' },
          { value: 3, label: '已取消' }
        ]
      },
      {
        key: 'CLASS_STAGE_LIST',
        value: [
          { value: 1, label: 'Term A' },
          { value: 2, label: 'Term B' },
          { value: 3, label: 'Term C' },
          { value: 4, label: 'Term D' },
          { value: 5, label: 'Term E' },
          { value: 6, label: 'Term F' },
          { value: 7, label: 'Term G' },
          { value: 8, label: 'Term H' },
          { value: 9, label: 'Term I' },
          { value: 10, label: 'Term J' },
          { value: 11, label: 'Term K' },
          { value: 12, label: 'Term L' },
          { value: 13, label: 'Term M' },
          { value: 14, label: 'Term N' },
          { value: 15, label: 'Term O' },
          { value: 16, label: 'Term P' },
          { value: 17, label: 'Term Q' },
          { value: 18, label: 'Term R' },
          { value: 19, label: 'Term S' },
          { value: 20, label: 'Term T' },
          { value: 21, label: 'Term U' },
          { value: 22, label: 'Term V' },
          { value: 23, label: 'Term W' },
          { value: 24, label: 'Term X' },
          { value: 25, label: 'Term Y' },
          { value: 26, label: 'Term Z' }
        ]
      },
      {
        key: 'MEMBER_STATUS',
        value: [
          { value: 0, label: '潜在' },
          { value: 1, label: '已成交' }
        ]
      },

      // 优惠券类型
      {
        key: 'COUPON_TYPE',
        value: [
          { value: 0, label: '现金券' },
          { value: 1, label: '满减券' },
          { value: 2, label: '折扣券' }
        ]
      },

      // 法人证件类型
      {
        key: 'LEGAL_CERT_TYPE',
        value: [
          { value: '00', label: '身份证' },
          { value: '01', label: '护照' },
          { value: '02', label: '军官证' },
          { value: '03', label: '士兵证' },
          { value: '04', label: '回乡证' },
          { value: '05', label: '户口本' },
          { value: '06', label: '外国护照' },
          { value: '07', label: '其他' },
          { value: '08', label: '暂住证' },
          { value: '09', label: '警官证' },
          { value: '10', label: '文职干部证' },
          { value: '11', label: '港澳台同胞通行证' },
          { value: '13', label: '外国人居留证' },
          { value: '14', label: '台胞证' },
          { value: '15', label: '港澳台居住证' }
        ]
      },
      // 营业执照类型
      {
        key: 'LICENSE_TYPE',
        value: [
          { value: 'NATIONAL_LEGAL', label: '营业执照' },
          { value: 'NATIONAL_LEGAL_MERGE', label: '营业执照(多证合一)' },
          { value: 'INST_RGST_CTF', label: '事业单位法人证书' }
        ]
      },

      // 公司类型
      {
        key: 'ENT_TYPE',
        value: [
          { value: 1, label: '政府机构' },
          { value: 2, label: '国营企业' },
          { value: 3, label: '私营企业' },
          { value: 4, label: '外资企业' },
          { value: 5, label: '个体工商户' },
          { value: 6, label: '其它组织' },
          { value: 7, label: '事业单位' }
        ]
      },
      // 银行账户类型
      {
        key: 'BANK_COUNT_TYPE',
        value: [
          { value: 0, label: '对公账户' },
          { value: 1, label: '对私法人账户' },
          { value: 2, label: '对私非法人账户' }
        ]
      },

      // 所属行业
      {
        key: 'MCC_TYPE',
        value: [{ value: 7413, label: '居民服务与商业服务-信息与计算机服务-其他在线应用或综合类' }]
      }
    ] as IDictList[]
  }),
  actions: {
    // 获取字典
    getDict(_key: string) {
      if (_key == null && _key == '') {
        return null
      }
      try {
        const list = this.initDictList.find((item) => item.key == _key)?.value
        return list
      } catch (e) {
        return null
      }
    },
    // 设置字典
    setDict(_key: string, value: IDict[]) {
      if (_key !== null && _key !== '') {
        this.dict.push({
          key: _key,
          value: value
        })
      }
    },
    // 删除字典
    removeDict(_key: string) {
      let bln = false
      try {
        for (let i = 0; i < this.dict.length; i++) {
          if (this.dict[i].key == _key) {
            this.dict.splice(i, 1)
            return true
          }
        }
      } catch (e) {
        bln = false
      }
      return bln
    },
    // 清空字典
    cleanDict() {
      this.dict = []
    },
    // 初始字典
    initDict() {
      // getSystemDict
      return new Promise((resolve, reject) => {
        getSystemDict(initDictTypeList.join(','))
          .then((res) => {
            const listObj = res.result || {}
            const keys = Object.keys(listObj)
            let dict = [] as IDictList[]
            if (keys.length > 0) {
              dict = keys.map((item) => {
                return {
                  key: item,
                  value: listObj[item].map((j: any) => {
                    return {
                      label: j.dictLabel as string,
                      value: Number(j.dictValue),
                      listClass: j.listClass
                    }
                  })
                }
              })
            }
            this.initDictList.unshift(...dict)
            this.dict = cloneDeep(this.initDictList)
            resolve(dict)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  }
})

export default useDictStore
