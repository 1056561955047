import request from '@/utils/request'

// 登录方法
export function login(params: any) {
  return request({
    url: '/tony-admin/user/login',
    headers: {
      isToken: false
    },
    method: 'get',
    params
  })
}
// 获取图形验证码
export function getLoginCode() {
  return request({
    url: '/tony-common/check-code',
    headers: {
      isToken: false
    },
    method: 'get'
  })
}

// 获取用户详细信息
export function getInfo(userId: number) {
  return request({
    url: '/tony-admin/user/' + userId,
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return Promise.resolve(true)
}
