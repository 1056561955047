import axios from 'axios'
import { ElNotification, ElMessage } from 'element-plus'
import { getToken, removeToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import { tansParams, encrypt } from '@/utils/find'
import type { IErrorType } from '@/utils/errorCode'
import cache from '@/plugins/cache'

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: import.meta.env.VITE_APP_BASE_DEV || import.meta.env.VITE_APP_BASE_API,
  // 超时
  timeout: 10000
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    // const encryptStatus = process.env.NODE_ENV === 'production'
    const encryptStatus = false
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
    const token = getToken()
    if (Object.prototype.toString.call(config.headers) === ' [object Object]') {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json;charset=utf-8',
        encrypt: encryptStatus
      } // 让每个请求携带自定义token 请根据实际情况自行修改
    } else {
      config.headers = {
        'Content-Type': 'application/json;charset=utf-8',
        encrypt: encryptStatus
      }
    }
    if (token && !isToken) {
      // 加密后post请求头headers的content-type变化了，需要重新设置一下
      config.headers = {
        ...config.headers,
        access_token: token
      } // 让每个请求携带自定义token 请根据实际情况自行修改
    }

    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      const encryptParmas = encryptStatus
        ? encrypt(tansParams(config.params))
        : tansParams(config.params)
      const url = config.url + '?' + encryptParmas
      // url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }
    if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
      // const convertData =
      //   typeof config.data === 'object' ? JSON.stringify(config.data) : config.data
      // const data = encryptStatus ? encrypt(convertData) : convertData
      const requestObj = {
        url: config.url,
        data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
        time: new Date().getTime()
      }
      const sessionObj = cache.session.getJSON('sessionObj')
      if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
        cache.session.setJSON('sessionObj', requestObj)
      } else {
        const s_url = sessionObj.url // 请求地址
        const s_data = sessionObj.data // 请求数据
        const s_time = sessionObj.time // 请求时间
        const interval = 500 // 间隔时间(ms)，小于此时间视为重复提交
        if (
          s_data === requestObj.data &&
          requestObj.time - s_time < interval &&
          s_url === requestObj.url
        ) {
          const message = '数据正在处理，请勿重复请求'
          console.warn(`[${s_url}]: ` + message)
          return Promise.reject(new Error(message))
        } else {
          cache.session.setJSON('sessionObj', requestObj)
        }
      }
      config.data = encryptStatus ? encrypt(config.data) : config.data
    }
    return config
  },
  (error: Error) => {
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200
    // 获取错误信息
    const msg = errorCode[code as keyof IErrorType] || res.data.message || errorCode['default']
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
      return res.data
    }
    if (code === 200 || code === 1000) {
      return Promise.resolve(res.data)
    } else {
      ElNotification.error({ title: msg })
      return Promise.reject('error')
    }
  },
  (error) => {
    console.log('err' + error)
    // 处理message
    let { message } = error
    if (message == 'Network Error') {
      message = '后端接口连接异常'
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时'
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常'
    }
    // 处理code
    const response = error?.response || null
    const status = response?.status || null
    if (status === 401) {
      ElMessage({ message: '登录状态已过期，需要重新登录', type: 'error', duration: 5 * 1000 })
      removeToken()
      // location.href = '/login'
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (status === 500) {
      ElMessage({ message: message, type: 'error' })
      return Promise.reject(new Error(message))
    } else if (status === 601) {
      ElMessage({ message: message, type: 'warning' })
      return Promise.reject(new Error(message))
    } else {
      ElMessage({ message: message, type: 'error', duration: 5 * 1000 })
      return Promise.reject(error)
    }
  }
)

export function download(url: string, params: any) {
  const token = getToken()
  if (token) {
    params.access_token = token
  }
  window.open(`${import.meta.env.VITE_APP_BASE_API}${url}?${tansParams(params)}`)
}

export default service
