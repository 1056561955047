import tab from './tab'
import cache from './cache'
import modal from './modal'
import { MessageParamsWithType, MessageBoxData } from 'element-plus'
import { NavigationFailure } from 'vue-router'

export default function installPlugins(app: {
  config: {
    globalProperties: {
      $tab: {
        refreshPage(obj: any): Promise<void>
        closeOpenPage(obj: any): Promise<void | NavigationFailure | undefined> | undefined
        closePage(
          obj: any // 缓存对象
        ): Promise<unknown>
        closeAllPage(): Promise<unknown>
        // 模态框对象
        closeLeftPage(obj: any): Promise<unknown>
        closeRightPage(obj: any): Promise<unknown>
        closeOtherPage(obj: any): Promise<unknown>
        openPage(url: any): Promise<void | NavigationFailure | undefined>
        updatePage(obj: any): void
      }
      $cache: {
        session: {
          set(key: string, value: string): void
          get(key: string): string | null
          setJSON(key: string, jsonValue: any): void
          getJSON(key: string): any
          remove(key: string): void
        }
        local: {
          set(key: string, value: string): void
          get(key: string): string | null
          setJSON(key: string, jsonValue: any): void
          getJSON(key: string): any
          remove(key: string): void
        }
      }
      $modal: {
        msgError(content: MessageParamsWithType): void
        msgSuccess(content: MessageParamsWithType): void
        loading(content: any): void
        closeLoading(): void
        confirm(content: string): Promise<MessageBoxData>
        prompt(content: string): Promise<MessageBoxData>
      }
    }
  }
}) {
  // 页签操作
  app.config.globalProperties.$tab = tab
  // 缓存对象
  app.config.globalProperties.$cache = cache
  // 模态框对象
  app.config.globalProperties.$modal = modal
}
