import router from './index'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken, removeToken } from '@/utils/auth'
import useSettingsStore from '@/store/modules/settings'
import useUserStore from '@/store/modules/user'
import usePermissionStore from '@/store/modules/permission'
import { ElMessage } from 'element-plus'
import { RouteRecordRaw } from 'vue-router'
import cache from '@/plugins/cache'
import useDictStore from '@/store/modules/dict'

NProgress.configure({ showSpinner: false })

const whiteList = ['/login']
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (!!getToken()) {
    to.meta.title && useSettingsStore().setTitle(String(to.meta.title))
    //   /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      const userStore = useUserStore()
      if (!userStore.userInfo || Object.keys(userStore.userInfo).length <= 0) {
        const userInfo = cache.local.getJSON('userInfo')
        userStore.$patch({
          userInfo
        })
      }

      const dictStore = useDictStore()
      if (dictStore.dict.length <= 0) {
        // dictStore.initDict()
      }
      const permissionStore = usePermissionStore()
      if (permissionStore.routes.length <= 0) {
        permissionStore
          .generateRoutes()
          .then((accessRoutes: RouteRecordRaw[]) => {
            accessRoutes.forEach((route) => {
              router.addRoute(route) // 动态添加可访问路由表
            })
            next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
          })
          .catch((err) => {
            ElMessage.error(err)
            removeToken()
            next({ path: '/' })
          })
      } else {
        next()
      }
    }
  } else {
    //   // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
      NProgress.done()
    } else {
      next('/login') // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
