import useDictStore from '@/store/modules/dict'
import { getDicts } from '@/api/system/dict/data'

interface IDict {
  [key: string]: any
}

/**
 * 获取字典数据
 */
export function useDict(...args: string[]) {
  const res = ref<IDict>({})
  return (() => {
    args.forEach((dictType) => {
      res.value[dictType] = []
      const dicts = useDictStore().getDict(dictType)
      if (dicts) {
        res.value[dictType] = dicts
      } else {
        getDicts(dictType).then((resp) => {
          res.value[dictType] = resp.result?.records?.map(
            (p: { dictLabel: any; dictValue: any; listClass: any }) => ({
              label: p.dictLabel,
              value: p.dictValue,
              listClass: p.listClass
            })
          )
          useDictStore().setDict(dictType, res.value[dictType])
        })
      }
    })
    return toRefs(res.value)
  })()
}
