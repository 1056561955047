<template>
  <div class="component-upload-image">
    <el-upload
      multiple
      :action="uploadImgUrl"
      :on-success="handleUploadSuccess"
      :before-upload="handleBeforeUpload"
      :on-error="handleUploadError"
      ref="imageUpload"
      :before-remove="handleDelete"
      :headers="headers"
      :on-preview="handlePictureCardPreview"
      :data="{
        path: props.imagePath,
        isPublic: props.isPublic
      }"
      class="avatar-uploader"
      method="post"
    >
      <img
        v-if="imageFile?.tempUrl"
        :src="imageFile.tempUrl"
        style="display: block; max-width: 180px; margin: 0 auto"
      />
      <el-icon v-else class="avatar-uploader-icon"><plus /></el-icon>
    </el-upload>

    <!-- 上传提示 -->
    <div class="el-upload__tip" v-if="showTip || tipText">
      <template v-if="showTip">
        请上传
        <template v-if="fileSize">
          大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b>
        </template>
        <template v-if="fileType">
          格式为 <b style="color: #f56c6c">{{ fileType.join('/') }}</b>
        </template>
        的文件
      </template>
      <template v-if="tipText">{{ tipText }}</template>
    </div>

    <el-dialog v-model="dialogVisible" title="预览" width="800px" append-to-body>
      <img :src="dialogImageUrl" style="display: block; max-width: 100%; margin: 0 auto" />
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import { getFileUrlItem } from '@/api/common'
import { getToken } from '@/utils/auth'
import { UploadFile } from 'element-plus'

const props = defineProps({
  modelValue: Object,
  // 大小限制(MB)
  fileSize: {
    type: Number,
    default: 50
  },
  // 文件类型, 例如['png', 'jpg', 'jpeg']
  fileType: {
    type: Array,
    default: () => ['png', 'jpg', 'jpeg']
  },
  // 是否显示提示
  isShowTip: {
    type: Boolean,
    default: true
  },
  tipText: {
    type: String,
    default: ''
  },
  // 图片在服务器中的文件夹
  imagePath: {
    type: String,
    default: ''
  },
  // 是否公共文件
  isPublic: {
    type: Boolean,
    default: false
  }
})

type FileType = {
  fileName: string
  objectKey: string
  tempUrl: string
  index?: number
}

const emit = defineEmits(['update:modelValue'])
const { proxy } = getCurrentInstance() as any

const imageFile = ref<FileType>()
const dialogImageUrl = ref<undefined | string>('')
const dialogVisible = ref(false)
// 上传的图片服务器地址
const uploadImgUrl = ref(
  `${import.meta.env.VITE_APP_BASE_API || ''}/tony-common/fileUpload/uploadFile`
)
const headers = ref({ access_token: getToken() })
const showTip = computed(() => props.isShowTip && (props.fileType || props.fileSize))
watch(
  () => props.modelValue,
  (val: any) => {
    console.log(val)
    if (!val.tempUrl) {
      imageFile.value = undefined
      return
    }
    if (val && !val.tempUrl?.includes('http') && !val.tempUrl?.includes('https')) {
      getFileUrlItem(val).then((res: any) => {
        const result = res.result
        imageFile.value = {
          fileName: val.fileName,
          objectKey: val.objectKey,
          tempUrl: result
        }
      })
    } else {
      imageFile.value = {
        fileName: val.fileName,
        objectKey: val.objectKey,
        tempUrl: val.tempUrl
      }
    }
  },
  { deep: true, immediate: true }
)

// 上传前loading加载
function handleBeforeUpload(file: File) {
  let isImg = false
  if (props.fileType.length) {
    let fileExtension = ''
    if (file.name.lastIndexOf('.') > -1) {
      fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1)
    }
    isImg =
      Array.isArray(props.fileType) &&
      props.fileType.some((type: unknown) => {
        if (file.type.indexOf(String(type)) > -1) return true
        if (fileExtension && fileExtension.indexOf(String(type)) > -1) return true
        return false
      })
  } else {
    isImg = file.type.indexOf('image') > -1
  }
  if (!isImg) {
    proxy.$modal.msgError(`文件格式不正确, 请上传${props.fileType.join('/')}图片格式文件!`)
    return false
  }
  if (props.fileSize) {
    const isLt = file.size / 1024 / 1024 < props.fileSize
    if (!isLt) {
      proxy.$modal.msgError(`上传头像图片大小不能超过 ${props.fileSize} MB!`)
      return false
    }
  }
  proxy.$modal.loading('正在上传图片，请稍候...')
}

// 上传成功回调
function handleUploadSuccess(res: any, file: UploadFile) {
  const result = res?.result
  if (result && !!result.fileName) {
    // console.log('result', result)
    imageFile.value = result
    uploadedSuccessfully()
  } else {
    proxy.$modal.closeLoading()
    proxy.$modal.msgError('上传图片失败')
    proxy.$refs.imageUpload.handleRemove(file)
    uploadedSuccessfully()
  }
}

// 删除图片
function handleDelete() {
  imageFile.value = undefined
  emit('update:modelValue', '')
  return true
}

// 上传结束处理
function uploadedSuccessfully() {
  if (!!imageFile.value) {
    console.log(imageFile.value)
    if (props.isPublic) {
      emit('update:modelValue', {
        tempUrl: imageFile.value.tempUrl,
        fileName: imageFile.value.fileName
      })
    } else {
      emit('update:modelValue', imageFile.value.objectKey)
    }

    proxy.$modal.closeLoading()
  }
}

// 上传失败
function handleUploadError() {
  proxy.$modal.msgError('上传图片失败')
  proxy.$modal.closeLoading()
}

// 预览
function handlePictureCardPreview(file: UploadFile) {
  dialogImageUrl.value = file.url
  dialogVisible.value = true
}
</script>

<style lang="scss">
// .el-upload--picture-card 控制加号部分

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
