<template>
  <div class="upload-file">
    <el-upload
      multiple
      :action="uploadFileUrl"
      :before-upload="handleBeforeUpload"
      :on-error="handleUploadError"
      :on-success="handleUploadSuccess"
      :headers="headers"
      class="upload-file-uploader"
      ref="fileUpload"
      :data="{
        path: props.imagePath,
        isPublic: props.isPublic,
        split: props.split
      }"
      method="post"
    >
      <!-- 上传按钮 -->
      <el-button type="primary">选取文件</el-button>
    </el-upload>
    <!-- 上传提示 -->
    <div class="el-upload__tip" v-if="showTip || tipText">
      <template v-if="showTip">
        请上传
        <template v-if="fileSize">
          大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b>
        </template>
        <template v-if="fileType">
          格式为 <b style="color: #f56c6c">{{ fileType.join('/') }}</b>
        </template>
        的文件
      </template>
      <template v-if="tipText">{{ tipText }}</template>
    </div>
    <!-- 文件列表 -->
    <transition-group
      class="upload-file-list el-upload-list el-upload-list--text"
      name="el-fade-in-linear"
      tag="ul"
    >
      <div class="el-upload-list__item ele-upload-list__item-content" v-if="!!uploadFile">
        <el-link :href="`${uploadFile.tempUrl}`" :underline="false" target="_blank">
          <span class="el-icon-document"> {{ getFileName(uploadFile.fileName) }} </span>
        </el-link>
        <div class="ele-upload-list__item-content-action">
          <el-link :underline="false" @click="handleDelete" type="danger">删除</el-link>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import { getFileUrlItem } from '@/api/common'
import { getToken } from '@/utils/auth'
import { FileType, AudioType, VideoType } from '@/utils/find'
import { UploadFile } from 'element-plus'

const props = defineProps({
  modelValue: String,
  // 数量限制
  limit: {
    type: Number,
    default: 5
  },
  // 大小限制(MB)
  fileSize: {
    type: Number,
    default: 50
  },
  // 文件类型, 例如['png', 'jpg', 'jpeg']
  fileType: {
    type: Array,
    default: () => ['mp3']
  },
  // 是否显示提示
  isShowTip: {
    type: Boolean,
    default: true
  },
  tipText: {
    type: String,
    default: ''
  },
  // 文件在服务器中的文件夹
  imagePath: {
    type: String,
    default: ''
  },
  // 是否公共文件
  isPublic: {
    type: Boolean,
    default: false
  },
  split: {
    type: Boolean,
    default: false
  }
})
type FileItemType = {
  fileName: string
  objectKey: string
  tempUrl: string
  index?: number
}
type FileType = FileItemType & {
  splitList?: FileItemType[]
}
const { proxy } = getCurrentInstance() as any
const emit = defineEmits(['update:modelValue', 'fileImageChange', 'getVideoDuration'])
const uploadFile = ref<FileType>()
// 上传文件服务器地址
const uploadFileUrl = ref(
  `${import.meta.env.VITE_APP_BASE_API || ''}/tony-common/fileUpload/uploadFile`
)
const headers = ref({ access_token: getToken() })
const showTip = computed(() => props.isShowTip && (props.fileType || props.fileSize))

watch(
  () => props.modelValue,
  (val) => {
    if (!val) {
      uploadFile.value = undefined
      return
    }
    if (val !== uploadFile.value?.objectKey) {
      getFileUrlItem(val).then((res: any) => {
        const result = res.result
        uploadFile.value = {
          fileName: val,
          objectKey: val,
          tempUrl: result
        }
      })
    }
  },
  { deep: true, immediate: true }
)

const videoDuration = ref(0)
// 上传前校检格式和大小
function handleBeforeUpload(file: File) {
  // 校检文件类型
  if (props.fileType.length) {
    const fileName = file.name.split('.')
    const fileExt = fileName[fileName.length - 1]
    const isTypeOk = props.fileType.indexOf(fileExt) >= 0
    if (!isTypeOk) {
      proxy.$modal.msgError(`文件格式不正确, 请上传${props.fileType.join('/')}格式文件!`)
      return false
    }
  }
  // 校检文件大小
  if (props.fileSize) {
    const isLt = file.size / 1024 / 1024 < props.fileSize
    if (!isLt) {
      proxy.$modal.msgError(`上传文件大小不能超过 ${props.fileSize} MB!`)
      return false
    }
  }

  // 获取视频时长
  if (JSON.stringify(props.fileType) === JSON.stringify(VideoType)) {
    let url = window.URL.createObjectURL(new Blob([file], { type: 'application/zip' }))
    let audioElement = new Audio(url)
    audioElement.addEventListener('loadedmetadata', function () {
      videoDuration.value = audioElement.duration //playTime就是当前视频长度
    })
  }

  proxy.$modal.loading('正在上传文件，请稍候...')
  return true
}

// 上传失败
function handleUploadError() {
  proxy.$modal.closeLoading()
  proxy.$modal.msgError('上传文件失败')
}

// 上传成功回调
function handleUploadSuccess(res: any, file: any) {
  const result = res?.result
  if (result && !!result.fileName) {
    uploadFile.value = result
    uploadedSuccessfully()
  } else {
    proxy.$modal.closeLoading()
    proxy.$modal.msgError('上传文件失败')
    proxy.$refs.fileUpload.handleRemove(file)
    uploadedSuccessfully()
  }
}

// 删除文件
function handleDelete() {
  uploadFile.value = undefined
  emit('update:modelValue', '')
}

// 上传结束处理
function uploadedSuccessfully() {
  if (!!uploadFile.value) {
    emit('update:modelValue', uploadFile.value.objectKey || uploadFile.value.tempUrl)
    if (props.split) {
      emit('fileImageChange', uploadFile.value.splitList)
    }
    if (JSON.stringify(props.fileType) === JSON.stringify(VideoType)) {
      emit('getVideoDuration', videoDuration.value)
    }
    proxy.$modal.closeLoading()
  }
}

// 获取文件名称
function getFileName(name: string) {
  if (name.lastIndexOf('/') > -1) {
    return name.slice(name.lastIndexOf('/') + 1)
  } else {
    return name
  }
}
</script>

<style scoped lang="scss">
.upload-file-uploader {
  margin-bottom: 5px;
}
.upload-file-list .el-upload-list__item {
  border: 1px solid #e4e7ed;
  line-height: 2;
  margin-bottom: 10px;
  position: relative;
}
.upload-file-list .ele-upload-list__item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
}
.ele-upload-list__item-content-action .el-link {
  width: 40px;
  margin-right: 10px;
}
</style>
