import { IDict } from './../store/modules/dict'
import cloneDeep from 'lodash-es/cloneDeep'
// import JSEncrypt from 'encryptlong'

export const FileType = ['pdf']
export const AudioType = ['mp3', 'MP3']
export const VideoType = ['mp4', 'MP4', 'mov', 'MOV']

// const key =
//   'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCRqXDhzSLE9Uxwc3GIyeI6TuszX7S+OJXpHmWWngbCoNk8cTK6JM1oBUJFD/Iie4jGZbWq6C7w5ZXcWbQ27lLDrNdJTpAA4a04zAYCl7KxLImNLt66db9bsMDFjncB/zuwKPPI1GlzcCkQb00bXqz4oQMU8QFdkLriT29YSqgBcQIDAQAB'
/**
 * 通用方法封装处理
 */
import type { IMenuItem } from '@/types/menu'

// 日期格式化
export function parseTime(time: object | string | number, pattern = '{y}-{m}-{d} {h}:{i}:{s}') {
  if (arguments.length === 0 || !time) {
    return ''
  }
  const format = pattern
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    } else if (typeof time === 'string') {
      time = time
        .replace(new RegExp(/-/gm), '/')
        .replace('T', ' ')
        .replace(new RegExp(/\.[\d]{3}/gm), '')
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: (date as Date).getFullYear(),
    m: (date as Date).getMonth() + 1,
    d: (date as Date).getDate(),
    h: (date as Date).getHours(),
    i: (date as Date).getMinutes(),
    s: (date as Date).getSeconds(),
    a: (date as Date).getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key: keyof typeof formatObj) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      return '0' + value
    }
    return String(value) || '0'
  })
  return time_str
}

// 判断时间是否在某个时间段内
export function isDuringDate(
  beginDate: object | string | number | undefined,
  endDate: object | string | number | undefined,
  currentDate?: object | string | number
) {
  const cur =
    typeof currentDate === 'object'
      ? currentDate
      : !!currentDate
      ? new Date(currentDate)
      : new Date()
  const begin =
    typeof beginDate === 'object' ? beginDate : !!beginDate ? new Date(beginDate) : new Date()
  const end = typeof endDate === 'object' ? endDate : !!endDate ? new Date(endDate) : new Date()
  if (cur >= begin && cur < end) {
    return true
  }
  return false
}

// 表单重置
export function resetForm(this: any, refName: string | number) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields()
  }
}

// 添加日期范围
export function addDateRange(
  params: any,
  dateRange: string[],
  propName?: string,
  rmoveEnd?: boolean
) {
  const payload =
    typeof params === 'object' && params !== null && !Array.isArray(params) ? params : {}
  const search = {
    ...cloneDeep(payload)
  }
  dateRange = Array.isArray(dateRange) ? dateRange : []
  if (dateRange.length > 0) {
    if (!propName) {
      search['beginTime'] = dateRange[0] + (!!rmoveEnd ? '' : ' 00:00:00')
      search['endTime'] = dateRange[1] + (!!rmoveEnd ? '' : ' 23:59:59')
    } else {
      search[propName + 'BeginTime'] = dateRange[0] + (!!rmoveEnd ? '' : ' 00:00:00')
      search[propName + 'EndTime'] = dateRange[1] + (!!rmoveEnd ? '' : ' 23:59:59')
    }
  }
  return search
}

// 回显数据字典
export function selectDictLabel(datas: any, value: string | number, key?: string) {
  if (value === '' || !Array.isArray(datas)) {
    return ''
  }
  return (
    datas?.find((item: { [x: string]: string }) => item[(key as string) || 'value'] == '' + value)
      ?.label || value
  )
}

export function getDictLabel(datas: IDict[], value: string | number) {
  if (value === undefined) {
    return ''
  }
  return datas[value]?.label
}

/**
 * 构造树型结构数据
 * @param {IMenuItem} data 数据源
 * @param {String} id id字段 默认 'id'
 * @param {String} parentId 父节点字段 默认 'parentId'
 * @param {String} children 孩子节点字段 默认 'children'
 */
export function handleTree(
  data: IMenuItem[],
  id = 'id',
  parentId = 'parentId',
  children = 'children'
) {
  const config = {
    id: id,
    parentId: parentId,
    childrenList: children
  }

  const childrenListMap = {}
  const nodeIds = {}
  const tree = []

  for (const d of data) {
    const parentIdKey = config.parentId
    const parentId = d[parentIdKey]
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = []
    }
    nodeIds[d[config.id]] = d
    childrenListMap[parentId].push(d)
  }

  for (const d of data) {
    const parentId = d[config.parentId]
    if (nodeIds[parentId] == null) {
      tree.push(d)
    }
  }

  for (const t of tree) {
    adaptToChildrenList(t)
  }

  function adaptToChildrenList(o) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]]
    }
    if (o[config.childrenList]) {
      for (const c of o[config.childrenList]) {
        adaptToChildrenList(c)
      }
    }
  }
  return tree
}

/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params: any) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName]
    const part = encodeURIComponent(propName) + '='
    if (value !== null && value !== '' && typeof value !== 'undefined') {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== '' && typeof value[key] !== 'undefined') {
            const params = propName + '[' + key + ']'
            const subPart = encodeURIComponent(params) + '='
            result += subPart + encodeURIComponent(value[key]) + '&'
          }
        }
      } else {
        result += part + encodeURIComponent(value) + '&'
      }
    }
  }
  // 截取掉字符串最后一个多余的&
  if (result.charAt(result.length - 1) === '&') {
    result = result.substring(0, result.length - 1)
  }
  return result
}

// 返回项目路径
export function getNormalPath(p) {
  if (p.length === 0 || !p || p == 'undefined') {
    return p
  }
  const res = p.replace('//', '/')
  if (res[res.length - 1] === '/') {
    return res.slice(0, res.length - 1)
  }
  return res
}

/** 计算年龄*/
export function calAge(birthYm: string) {
  if (!birthYm) return '暂无'
  const birthYear = Number(birthYm.substring(0, 4))
  const birthMonth = Number(birthYm.substring(5, 2))
  const currentDate = new Date()
  return currentDate.getFullYear() - birthYear + (currentDate.getMonth() >= birthMonth ? 1 : -1)
}

/** 手机号脱敏*/
export function phoneDesensitize(phone: string | number) {
  if (!phone) return '-'
  const pat = /(\d{3})\d*(\d{4})/
  return String(phone).replace(pat, '$1****$2')
}
/** 银行卡脱敏*/
export function cardDesensitize(card: string | number) {
  if (!card) return '暂无'
  const pat = /(\d{4})\d*(\d{4})/
  return String(card).replace(pat, '$1****$2')
}
/** 身份证脱敏*/
export function idCardDesensitize(card: string | number) {
  if (!card) return '暂无'
  const pat = /(\d{6})\d*/
  return String(card).replace(pat, '$1****')
}

/**
根据身份证号码判断性别
15位身份证号码：第7、8位为出生年份(两位数)，第9、10位为出生月份，第11、12位代表出生日
18位身份证号码：第7、8、9、10位为出生年份(四位数)，第11、第12位为出生月份，
第13、14位代表出生日期，第17位代表性别，奇数为男，偶数为女。
*/
//根据身份证号获取年龄
export function getAge(identityCard: string) {
  const len = (identityCard + '').length
  let strBirthday = ''
  if (len == 18) {
    //处理18位的身份证号码从号码中得到生日和性别代码
    strBirthday =
      identityCard.substr(6, 4) +
      '/' +
      identityCard.substr(10, 2) +
      '/' +
      identityCard.substr(12, 2)
  }
  if (len == 15) {
    let birthdayValue = ''
    birthdayValue = identityCard.charAt(6) + identityCard.charAt(7)
    if (parseInt(birthdayValue) < 10) {
      strBirthday =
        '20' +
        identityCard.substr(6, 2) +
        '/' +
        identityCard.substr(8, 2) +
        '/' +
        identityCard.substr(10, 2)
    } else {
      strBirthday =
        '19' +
        identityCard.substr(6, 2) +
        '/' +
        identityCard.substr(8, 2) +
        '/' +
        identityCard.substr(10, 2)
    }
  }
  //时间字符串里，必须是“/”
  const birthDate = new Date(strBirthday)
  const nowDateTime = new Date()
  let age = nowDateTime.getFullYear() - birthDate.getFullYear()
  //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
  if (
    nowDateTime.getMonth() < birthDate.getMonth() ||
    (nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())
  ) {
    age--
  }
  return age
}

// 根据生日计算年龄
export function getBirthYmAge(strBirthday: string) {
  if (strBirthday.length <= 0) return 0
  let returnAge
  // 根据生日计算年龄
  //以下五行是为了获取出生年月日，如果是从身份证上获取需要稍微改变一下
  const strBirthdayArr = strBirthday.split('-')
  const birthYear = Number(strBirthdayArr[0] || 0)
  const birthMonth = Number(strBirthdayArr[1] || 1)
  const birthDay = Number(strBirthdayArr[2] || 1)

  const d = new Date()
  const nowYear = d.getFullYear()
  const nowMonth = d.getMonth() + 1
  const nowDay = d.getDate()

  if (nowYear == birthYear) {
    returnAge = 0 //同年 则为0岁
  } else {
    const ageDiff = nowYear - birthYear //年之差
    if (ageDiff > 0) {
      if (nowMonth == birthMonth) {
        const dayDiff = nowDay - birthDay //日之差
        if (dayDiff < 0) {
          returnAge = ageDiff - 1
        } else {
          returnAge = ageDiff
        }
      } else {
        const monthDiff = nowMonth - birthMonth //月之差
        if (monthDiff < 0) {
          returnAge = ageDiff - 1
        } else {
          returnAge = ageDiff
        }
      }
    } else {
      returnAge = -1 //返回-1 表示出生日期输入错误 晚于今天
    }
  }

  return returnAge //返回周岁年龄
}

// 日期时间换行
export function wrapDateTime(dateTime: any) {
  return dateTime.split(' ')
}

// 文字只显示前几位
export function splitStr(str: string, index: number) {
  return str.length > index ? str.substring(0, index).concat('...') : str
}

// 数值只能保留两位小数并且不能输入负数和0
export function regFloat(params: any) {
  return (params = params.match(/^([1-9]{1}[0-9]{0,8})+(\.\d{0,2})?/)
    ? params.match(/^([1-9]{1}[0-9]{0,8})+(\.\d{0,2})?/)[0]
    : undefined)
}

// 只能输入整数,输入num个整数
export function regNum(params: any, hasZero: boolean, num = 20) {
  const reg = hasZero
    ? new RegExp('^([0-9]{1}[0-9]{0,' + num + '})$')
    : new RegExp('^([1-9]{1}[0-9]{0,' + num + '})$')

  return reg?.test(params) ? Number(params) : undefined
}

export const encrypt = (payload: string | any) => {
  // const en = new JSEncrypt()
  // en.setPublicKey(key)
  return '' // en.encryptLong(typeof payload === 'object' ? JSON.stringify(payload) : payload)
}

// 复制
export const copyText = (text: string) => {
  const copyInput = document.createElement('input')
  document.body.appendChild(copyInput)
  copyInput.setAttribute('value', text)
  copyInput.select()
  document.execCommand('Copy')
  copyInput.remove()
}

// 字母和数字互相转换
export const numberToWord = (num: number) => {
  return String.fromCharCode(65 + num)
}

export const wordToNum = (letter: string) => {
  return letter.toUpperCase().charCodeAt(0) - 65
}

// 秒转换成时分秒
export const getTimeDuration = (duration: any) => {
  let h:any = parseInt(duration / 60 / 60 % 24)
  h = h < 10 ? '0' + h : h
  let m:any = parseInt(duration / 60 % 60)
  m = m < 10 ? '0' + m : m
  let s:any = parseInt(duration % 60)
  s = s < 10 ? '0' + s : s
  // return `${h}:${m}:${s}`
  return `${m}:${s}`

}
